import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import Arrow from "../../../elements/V2/Arrow"
import TextButton from "../../../elements/V2/Buttons/TextButton"
import IconInfoBlock from "../../../elements/V2/IconInfoBlock"

import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"
import { getAccentColor, getBackgroundColorClass } from "@utils/V2/color"

type BaseProps = {
  backgroundColor: Color
  iconUrl?: string
  iconAlt?: string
  titleText: string
  descriptionText: string
  size: "large" | "medium"
}

type LargeProps = BaseProps &
  Partial<{
    // size: "large"
    buttonText?: string
    buttonLink?: string
  }>

type MediumProps = BaseProps & {
  // size: "medium"
}

const Intro: React.FC<LargeProps & MediumProps> = ({
  backgroundColor,
  descriptionText,
  titleText,
  iconUrl,
  iconAlt,
  size,
  buttonText,
  buttonLink,
  ...props
}) => {
  const { animationRefCallback } = useScrollAnimateSelf(true)

  return (
    <div
      ref={animationRefCallback}
      className={classNames(
        getBackgroundColorClass(backgroundColor),
        "flex flex-col gap-16-v2 p-lg-v2 col-span-full md-v2:col-span-2 lg-v2:col-span-4 rounded-md-v2 justify-between"
      )}
      {...props}
    >
      <IconInfoBlock
        size={size}
        textColor={getAccentColor(backgroundColor)}
        bodyText={descriptionText}
        titleText={titleText}
        iconUrl={iconUrl}
        iconAlt={iconAlt}
        {...props}
      />

      {size === "large" && buttonText && buttonLink && (
        <TextButton
          color={getAccentColor(backgroundColor)}
          text={buttonText}
          linkUrl={buttonLink}
          style="icon"
          icon={<Arrow />}
        />
      )}
    </div>
  )
}

export default Intro
